export const formatTime = (time) => {
  if (time < 10) {
    time = '0' + time
  }
  return time
}

export const getTimeString = (date) => {
  return formatTime(date.getHours()) + ':' + formatTime(date.getMinutes())
}

export const getDayString = (date) => {
  const str =
    formatTime(date.getDate()) +
    '.' +
    formatTime(date.getMonth() + 1) +
    '.' +
    date.getFullYear()

  // console.log(date + ' -> ' + str)

  return str
}

export const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'] as const
export const daysLong = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
]

export const getDayName = (date) => {
  return days[date.getDay()]
}

export const getDayNameLong = (date) => {
  return daysLong[date.getDay()]
}

export const getCurrentDayKey = () => {
  const now = new Date()
  const day = days[now.getDay()]
  if (day === 'Di') {
    return 'Mi'
  }
  return day
}
