import maplibregl, { LngLatLike, Map, Marker } from 'maplibre-gl'
import {
  TENT_ICON_KEY,
  TENT_INITIAL_POSITION,
  TENT_LOCKED_KEY,
} from './constants/map'
import { EXITS, FLOORS, WATER, WC } from './layers'

const INTERPOLATION_FACTOR = 0.0018

export const createTent = (
  map: Map,
  dragMeText: string,
  lockMeText: string,
): Marker => {
  const tentPosString = localStorage.getItem(TENT_ICON_KEY)
  const tentLockedString = localStorage.getItem(TENT_LOCKED_KEY)

  const tent = document.createElement('div')

  const aspectRatio = 0.73

  const tentHeight = 60
  const tentWidth = tentHeight * aspectRatio

  tent.className = 'tentMarker'
  tent.style.width = `${tentWidth}px`
  tent.style.height = `${tentHeight}px`
  tent.style.backgroundSize = '100%'

  let tentPos: any = undefined

  if (tentPosString) {
    tentPos = JSON.parse(tentPosString)
  }

  const popup = new maplibregl.Popup({
    closeButton: false,
    anchor: 'top',
  }).setText(dragMeText)

  const tentMarker = new Marker({
    element: tent,
    draggable: true,
    anchor: 'bottom',
  })
    .setLngLat(
      tentPos
        ? [tentPos.lng, tentPos.lat]
        : (TENT_INITIAL_POSITION as LngLatLike),
    )
    .addTo(map)

  if (!tentPosString) {
    tentMarker.setPopup(popup)
    tentMarker.togglePopup()
  } else if (!tentLockedString) {
    tentMarker.setPopup(popup)
    popup.setText(lockMeText)
    tentMarker.togglePopup()
  }

  const CAMPING = [FLOORS, EXITS, WATER, WC]

  tentMarker.on('dragstart', () => {
    for (const lId of CAMPING) {
      map.setPaintProperty(lId, 'raster-opacity', 0)
    }
  })

  tentMarker.on('dragend', () => {
    const lngLat = tentMarker.getLngLat()

    if (!tentLockedString) {
      popup.setText(lockMeText)
    }

    localStorage.setItem(TENT_ICON_KEY, JSON.stringify(lngLat))

    for (const lId of CAMPING) {
      map.setPaintProperty(lId, 'raster-opacity', 1)
    }
  })

  map.on('zoom', () => {
    const zoom = map.getZoom()

    const interpolatedHeight = Math.pow(2, zoom) * INTERPOLATION_FACTOR
    const interpolatedWidth = interpolatedHeight * aspectRatio

    tent.style.width =
      interpolatedWidth < tentWidth
        ? tentWidth + 'px'
        : interpolatedWidth + 'px'

    tent.style.height =
      interpolatedHeight < tentHeight
        ? tentHeight + 'px'
        : interpolatedHeight + 'px'
  })

  return tentMarker
}
