import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './FilterSelects.css'
import SearchInput from './SearchInput'
import { FloorInfo } from '../FloorInfo/FloorInfo'
import {
  closeFilter as close,
  openFilter as open,
  changeFilter as change,
  // resetFilter as reset,
  getFilters,
  ChangeFilterPayload,
} from '../state/filter/filterSlice'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import ArtistInfo from '../ArtistInfo/ArtistInfo'

const FilterSelects = () => {
  const filters = useAppSelector(getFilters)
  const dispatch = useAppDispatch()

  const closeFilter = () => dispatch(close())
  // const resetFilter = (id: string) => dispatch(reset(id))
  const openFilter = (id: string) => dispatch(open(id))
  const changeFilter = (payload: ChangeFilterPayload) =>
    dispatch(change(payload))

  return (
    <React.Fragment>
      {/* <CSSTransition
        in={filters.openedFilter === 'types'}
        timeout={300}
        classNames="types"
        unmountOnExit
        mountOnEnter
        // onExited={}
      >
        {(state) => {
          // console.log('types state: ', state)
          // if (state === 'exited') {
          //   return null
          // }
          return (
            <div className="types">
              <FilterSelect
                filterId="types"
                options={types}
                show={state !== 'exited'}
                value={filters.types}
                onClose={closeFilter}
                onReset={() => resetFilter('types')}
                onChange={(value) => {
                  changeFilter({ filterId: 'types', value })
                }}
              />
            </div>
          )
        }}
      </CSSTransition> */}

      {/* <CSSTransition
        in={filters.openedFilter === 'floors'}
        timeout={300}
        classNames="floors"
        mountOnEnter
        unountOnExit
        // onExited={}
      >
        {(state) => {
          // console.log('floors state: ', state)
          // if (state === 'exited') {
          //   return null
          // }
          return (
            <div className="floors">
              <FilterSelect
                filterId="floors"
                options={floors}
                show={state !== 'exited'}
                value={filters.floors}
                onClose={closeFilter}
                openFilter={openFilter}
                changeFilter={changeFilter}
                onReset={() => resetFilter('floors')}
                onChange={(value) => {
                  changeFilter({ filterId: 'floors', value })
                }}
              />
            </div>
          )
        }}
      </CSSTransition> */}

      <CSSTransition
        in={filters.openedFilter === 'search'}
        timeout={300}
        classNames="search"
        mountOnEnter
        unountOnExit
      >
        {(state) => {
          return (
            <div className="search">
              <SearchInput
                show={state !== 'exited'}
                value={filters.search}
                onClose={closeFilter}
                onChange={(value) => {
                  changeFilter({ filterId: 'search', value })
                }}
              />
            </div>
          )
        }}
      </CSSTransition>

      <CSSTransition
        in={filters.openedFilter === 'info'}
        timeout={300}
        classNames="info"
        mountOnEnter
        unountOnExit
      >
        {
          (/*state: any*/) => {
            return (
              <div className="info">
                <ArtistInfo id={filters.info} key={filters.info} />
              </div>
            )
          }
        }
      </CSSTransition>

      <CSSTransition
        in={filters.openedFilter === 'floorInfo'}
        timeout={300}
        classNames="floorInfo"
        mountOnEnter
        unountOnExit
      >
        {(state) => {
          // console.log('floorInfo state: ', state)
          if (state === 'exited') {
            return null
          }

          return (
            <div className="floorInfo">
              <FloorInfo
                floor={filters.floorInfo}
                onClose={() => openFilter('floors')}
              />
            </div>
          )
        }}
      </CSSTransition>
    </React.Fragment>
  )
}
export default FilterSelects
