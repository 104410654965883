import throttle from 'lodash/throttle'
// import * as R from 'ramda'
import { saveState } from '../helper/LocalStorage'
import { RootState } from './store'

/**
 * Throttled function to save the state to localStorage not more then once every 3 sec
 */
const makeSaveToLocalStorage = (delay) =>
  throttle((state: RootState, save = saveState) => {
    // const reducedState = R.omit(
    //   // ['locales', 'topContracts', 'topHardware', 'form'],
    //   state
    // )
    // console.log('save state', state)
    const stateToSave = {
      filter: state.filter,
      timetable: {
        favorites: state.timetable.favorites,
        artistExtras: state.timetable.artistExtras,
      },
    }

    save(stateToSave)
  }, delay)

const SAVE_DELAY_TIME = 1000

export default function saveStoreMiddleWare({
  getState,
  save = makeSaveToLocalStorage(SAVE_DELAY_TIME),
}) {
  return (next) => (action) => {
    // console.log('will dispatch', action)
    // console.log('process.env.NODE_ENV: ', process.env.NODE_ENV)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

    save(getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}

export const __test__ = {
  makeSaveToLocalStorage,
  SAVE_DELAY_TIME,
}
