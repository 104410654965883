import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import KeyVisualImg from '../images/attension-keyvisual.png'
// import KeyVisualImg from '../images/no-photos.png'
import {
  Page,
  Content,
  Row,
  Spacer,
  Button,
  Text,
  T,
  BottomDocked,
} from '../components'
import { VERSION } from '../const'
import onReloadClick from './reloadAppClick'
import { useLang, useSetLang } from '../state/language'
import { LAST_BUILD_TIME } from '../lastBuildTime'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../state/hooks'
import { importFavs } from '../state/timetable/timetableSlice'

const VersionText = styled.div`
  font-size: 12px;
  color: #6e6e6e;
  padding: 0.5rem 0;
`

const KeyVisualContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`
const KeyVisual = styled.img`
  width: 80%;
  max-height: 120%;
  object-fit: contain;
`

const BottomDockedStyled = styled(BottomDocked)`
  padding-bottom: calc(0px + env(safe-area-inset-bottom) * 4);
`

const BottomSpacer = styled.div`
  /* background-color: red; */
  height: 40px;
`

export const LanguageSelection = () => {
  const setLang = useSetLang()
  const lang = useLang()
  const lastUpdateStr = new Date(LAST_BUILD_TIME).toLocaleString()
  const [favsImported, setFavsImported] = useState(-1)
  const [clicks, setClicks] = useState(0)
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()

  const code = params.code

  useEffect(() => {
    if (code) {
      try {
        // let codeStr = decodeURIComponent(code)
        // const favs = JSON.parse(codeStr)
        const favs = code.split('-')
        // console.log('Import Favs: ', favs)
        dispatch(importFavs(favs))
        setFavsImported(favs.length)
        window.location.hash = ''
      } catch (error) {}
    }
  }, [code, dispatch])

  useEffect(() => {
    if (clicks > 5) {
      setLang('de')
      navigate('/start')
    }
  }, [clicks, navigate, setLang])

  useEffect(() => {
    if (lang) {
      navigate('/start')
    }
  }, [lang, navigate])

  return (
    <Page>
      <Content
        padding={'0'}
        style={
          // eslint-disable-next-line react-native/no-inline-styles
          { flex: 1, paddingBottom: 30 }
        }
      >
        <KeyVisualContainer onClick={onReloadClick}>
          <KeyVisual src={KeyVisualImg} />
        </KeyVisualContainer>
        {favsImported > 0 && (
          <Text>
            {favsImported} <T id="FAVORITES_IMPORT_SUCCESS" />
          </Text>
        )}
      </Content>
      <BottomSpacer />

      <BottomDockedStyled className="ios-extra-padding">
        <Row flexDirection="row" alignItems="initial">
          {process.env.REACT_APP_IS_NOT_REALEASED === 'true' ? (
            <div>not released</div>
          ) : (
            <>
              <Button
                onClick={() => {
                  setLang('de')
                  navigate('/start')
                }}
                mr={4}
              >
                Deutsch
              </Button>
              <Spacer />
              <Button
                onClick={() => {
                  setLang('en')
                  navigate('/start')
                }}
              >
                English
              </Button>
            </>
          )}
        </Row>

        <VersionText onClick={() => setClicks((click) => click + 1)}>
          V {VERSION} {lastUpdateStr}
        </VersionText>
      </BottomDockedStyled>
    </Page>
  )
}
