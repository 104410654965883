import React, { FC, useCallback, useRef } from 'react'
import styled from 'styled-components'
import Entries from './Entries'
import Selection from './Selection'
import { Row, Message, Button } from '../components'
import { PageTitle } from '../components/PageTitle'
import { getTimeString } from '../helper/DateFornat'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import {
  changeFilter as chnageFilterAction,
  getFilters,
} from '../state/filter/filterSlice'

const Page = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10rem;
`
const BottomButton = styled.div`
  padding: 2rem;
`

export type ListType = 'favorites' | 'artists' | 'timetable'

export type TimetableListProps = {
  // navigate: any
  isLoading: boolean
  type?: ListType
  orderBy: 'name' | 'time'
  // toggleScrollButton: (showTopButton: boolean) => void
  entries: any
  day: string
}

const titles = {
  favorites: <Message id="BUTTON_FAVORITES" />,
  timetable: <Message id="BUTTON_TIMETABLE" />,
  artists: <Message id="BUTTON_ARTISTS" />,
}

const daysToNumber = {
  So: 0,
  Mo: 1,
  Di: 2,
  Mi: 3,
  Do: 4,
  Fr: 5,
  Sa: 6,
}

const TimetableList: FC<TimetableListProps> = ({
  entries,
  type = 'timetable',
  orderBy,
  // toggleFav,
  day,
  isLoading,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const filters = useAppSelector(getFilters)
  const dispatch = useAppDispatch()

  const scrollToTop = useCallback(() => {
    containerRef.current?.scrollTo({ top: 0 })
  }, [])

  const changeFilter = useCallback(
    (payload) => dispatch(chnageFilterAction(payload)),
    [dispatch],
  )
  // Scroll to top when day or type changes
  // useEffect(() => {
  //   scrollToTop()
  // }, [day, type, scrollToTop])

  const isFavoritesList = type === 'favorites'
  const isSearch = !!filters.search && !isFavoritesList

  const now = new Date()
  const isCurrentDay = now.getDay() === daysToNumber[day]
  now.setMinutes(0)
  const currentTime = getTimeString(now)

  return (
    <Page>
      <Content id="myContent" ref={containerRef}>
        <PageTitle title={titles[type]} />
        <Selection
          day={day}
          filters={filters}
          changeFilter={changeFilter}
          scrollToTop={scrollToTop}
          alwaysDayButtons={isFavoritesList && orderBy === 'time'}
          hideDayButtons={orderBy === 'name'}
          onlyDayButtons={isFavoritesList}
        />

        <Entries
          isLoading={isLoading}
          entries={entries}
          // day={day}
          // toggleFav={toggleFav}
          type={type}
          orderBy={orderBy}
          isSearch={isSearch}
          scrollToTime={isCurrentDay ? currentTime : undefined}
        />

        {entries.length > 0 && !filters.search && (
          <>
            <Selection
              day={day}
              filters={filters}
              changeFilter={changeFilter}
              scrollToTop={scrollToTop}
              alwaysDayButtons={isFavoritesList && orderBy === 'time'}
              hideDayButtons={orderBy === 'name'}
              onlyDayButtons
            />
          </>
        )}

        {isFavoritesList && (
          <BottomButton>
            <Row>
              <Button onClick={() => navigate('/importExport')}>
                <Message id="IMPORT_EXPORT_BTN" />
              </Button>
            </Row>
          </BottomButton>
        )}
      </Content>
    </Page>
  )
}

export default TimetableList
