export function waitMs(
  ms: number,
  abortController?: AbortController,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const abortFn = () => {
      clearTimeout(timeout)
      reject()
    }

    const timeout = setTimeout(() => {
      abortController?.signal.removeEventListener('abort', abortFn)
      resolve()
    }, ms)

    abortController?.signal.addEventListener('abort', abortFn, { once: true })
  })
}
