import React, { useEffect } from 'react'
import useDebouncedState from '../hooks/useDebouncedState'
import { InputSearch } from '../components/Input/InputSearch'
import { useT } from '../state/language'
import { Config } from '../const'
import { ChangeFilterPayload } from '../state/filter/filterSlice'

type Props = {
  changeFilter: (payload: ChangeFilterPayload) => void
}

const SearchField: React.FC<Props> = ({ changeFilter }) => {
  const [value, debouncedValue, setValue] = useDebouncedState('', 500)
  const t = useT()

  useEffect(() => {
    changeFilter({ filterId: 'search', value: debouncedValue })
  }, [debouncedValue, changeFilter])

  const hideShows = Config.REACT_APP_SHOW_ARTISTS

  return (
    <InputSearch
      placeholder={
        hideShows ? t('SEARCH_PLACEHOLDER_ARTIST') : t('SEARCH_PLACEHOLDER')
      }
      value={value}
      onChange={setValue}
      autoFocus={false}
    />
  )
}

export default SearchField
