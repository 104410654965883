/* eslint-disable react-native/no-inline-styles */
import React, { useState, useCallback, useMemo } from 'react'
import useClipboard from '../hooks/useClipboard'
import {
  Button,
  H3,
  Text,
  Spacer,
  Page,
  Content,
  BottomDocked,
  Row,
  Divider,
} from '../components'
import styled from 'styled-components'
import ReloadApp from './ReloadApp'
import useShare from '../hooks/useShare'
import T from '../components/T'
import { useT } from '../state/language'
import InputComp from '../components/Input/Input'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { getFavorites, importFavs } from '../state/timetable/timetableSlice'

const Input = styled(InputComp)`
  &&& {
    background: black;
  }
`

const useMailText = (favorites) => {
  const t = useT()
  const favoritesStr = useMemo(() => JSON.stringify(favorites), [favorites])
  const favoritesForLink = favorites.join('-')

  const subject = t('FAVORITES_SHARE_TITLE')
  const link =
    t('FAVORITES_SHARE_URL') +
    '/#/import/' +
    encodeURIComponent(favoritesForLink)
  const mailBody = useMemo(
    () => `${t('FAVORITES_SHARE_TEXT', link)}

    --- START ---
    ${favoritesStr}
    --- ENDE ---

    ${t('FAVORITES_SHARE_URL')}
    `,
    [favoritesStr, link, t],
  )

  const mailTo = `mailto:?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(mailBody)}`

  return {
    favoritesStr,
    //  subject,
    mailBody,
    mailTo,
  }
}

const ImportExport = () => {
  const dispatch = useAppDispatch()

  const favorites = useAppSelector(getFavorites)

  const [importTxt, setImportTxt] = useState('')
  const [error, setError] = useState('')
  const t = useT()
  const navigate = useNavigate()

  const { favoritesStr, mailBody, mailTo } = useMailText(favorites)

  const [, setClipboard] = useClipboard(favoritesStr)

  const validateImport = useCallback(() => {
    // console.log('validateImport')
    try {
      let str = importTxt

      try {
        const regEx = new RegExp('(?<=--- START ---)(.*)(?=--- ENDE ---)', 's')
        const matched = importTxt.match(regEx)
        if (matched && matched[0]) {
          str = matched[0]
        }
      } catch (err) {}

      str = str.trim()
      // console.log('cleanString:', str)

      const newFavorites = JSON.parse(str)

      // console.log('Importiere: ', newFavorites)
      dispatch(importFavs(newFavorites))
      setError('')
      alert(`${newFavorites.length} ${t('FAVORITES_IMPORT_SUCCESS')}`)
      setImportTxt('')
    } catch (err: any) {
      setError(t('FAVORITES_IMPORT_ERROR') + ' :: ' + err.toString())
      console.warn(err)
    }
  }, [importTxt, dispatch, t])

  const share = useShare(mailBody)

  return (
    <Page>
      <Content>
        <H3>
          <T id="FAVORITES_EXPORT_HEADLINE" />
        </H3>

        <Spacer size={2} />
        {!!navigator.share ? (
          <React.Fragment>
            <Text>
              <T id="FAVORITES_SHARE_DESC" />
            </Text>
            <Button onClick={share}>
              <T id="FAVORITES_SHARE_BTN" />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text>
              <T id="FAVORITES_EMAIL_DESC" />
            </Text>

            <Button as="a" href={mailTo} style={{ display: 'inline-block' }}>
              <T id="FAVORITES_EMAIL_BTN" />
            </Button>
          </React.Fragment>
        )}

        <Spacer size={2} />
        <Text>
          <T id="FAVORITES_CLIPBOARD_DESC" />
        </Text>
        <Button
          onClick={() => {
            setClipboard()
            alert(t('FAVORITES_COPY_CLIPBOARD_SUCCESS'))
          }}
        >
          <T id="FAVORITES_CLIPBOARD_BTN" />
        </Button>

        <Spacer size={4} />
        <Divider />
        <Spacer size={4} />

        <H3>
          <T id="FAVORITES_IMPORT_HEADLINE" />
        </H3>
        <Text>
          <T id="FAVORITES_IMPORT_DESC" />
        </Text>

        <Input value={importTxt} onChange={(value) => setImportTxt(value)} />
        <Spacer />
        {error && <Text>{error}</Text>}
        <Button flex="initial" onClick={validateImport}>
          <T id="FAVORITES_IMPORT_BTN" />
        </Button>

        <Spacer size={4} />
        <Divider />
        <Spacer size={4} />
        <ReloadApp />
      </Content>
      <BottomDocked>
        <Row>
          <Button onClick={() => navigate(-1)}>
            <T id="FAVORITES_CLOSE_BTN" />
          </Button>
        </Row>
      </BottomDocked>
    </Page>
  )
}

export default ImportExport
