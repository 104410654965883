import classes from './PulsingCircle.module.css'

const colorClasses = {
  red: classes.red,
  yellow: classes.yellow,
  green: classes.green,
}

export const PulsingCircle = ({ status }: { status: string }) => {
  const statusColor = colorClasses[status]
  return (
    <div className={classes.pulsewrapper}>
      <div className={classes.pulse + ' ' + [statusColor]}>
        <div className={classes.ring}></div>
        <div className={classes.ring}></div>
        <div className={classes.ring}></div>
        <div className={classes.ring}></div>
      </div>
    </div>
  )
}
