import { getEntriesForArtists, getEntriesForHours } from './selectors'
import TimetableList, { ListType } from './TimetableList'
import { Config } from '../const'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { FC, useEffect } from 'react'
import { getDay } from '../state/filter/filterSlice'
import { isLoading, timetableActions } from '../state/timetable/timetableSlice'

const { setLoading, setArtists } = timetableActions

type Props = {
  type?: ListType
}

export const TimetableListContainer: FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch()

  const isFavoritesList = type === 'favorites'

  const orderBy = Config.REACT_APP_SHOW_ARTISTS ? 'name' : 'time'

  const entriesByName = useAppSelector((state) =>
    getEntriesForArtists(state, isFavoritesList),
  )
  const entriesByHours = useAppSelector((state) =>
    getEntriesForHours(state, isFavoritesList),
  )

  const entries =
    orderBy === 'name'
      ? // @ts-ignore
        entriesByName
      : // @ts-ignore
        entriesByHours

  const loading = useAppSelector(isLoading)
  const day = useAppSelector(getDay)

  // console.log('entries: ', {
  //   entriesByName,
  //   entriesByHours,
  //   entries,
  //   orderBy,
  // })

  useEffect(() => {
    const loadTimetable = async () => {
      dispatch(setLoading(true))

      import('../artists.json')
        .then((m) => m.default)
        .then((loadedArtists) => {
          // eslint-disable-next-line no-console
          console.log('Artists loaded')

          const artists = loadedArtists
          dispatch(setArtists(artists))
        })
    }

    loadTimetable()
  }, [dispatch])

  return (
    <TimetableList
      day={day}
      entries={entries}
      isLoading={loading}
      orderBy={orderBy}
      type={type}
    />
  )
}
