import * as R from 'ramda'
import { getDayString, getTimeString } from '../../helper/DateFornat'
import { daysOfWeek } from '../filter'
import { floors } from '../filter/consts'
import { Artist, Show, TimetableEntry } from './types'

export function decodeTimetable(artists: Artist[]): TimetableEntry[] {
  const flatArtist = (entry: Artist) => {
    const newEntry = {
      ...entry,
    }

    const shows = R.map((show: Show) => {
      const newShow = {
        ...show,
        ...entry,
      }
      delete newShow.shows
      return newShow
    })(entry.shows || [])

    newEntry.shows = shows

    return newEntry
  }

  const timetable = R.pipe(
    R.map(flatArtist),
    R.map(R.prop('shows')),
    R.flatten,
    R.map((entry) => {
      const start = new Date(entry.start)
      const end = new Date(entry.end)

      let to = getTimeString(end)

      const hours = (end.getTime() - start.getTime()) / 1000 / 60 / 60
      if (hours > 12) {
        to = daysOfWeek[end.getDay()] + ' ' + getTimeString(end)
      }

      const newEntry = {
        ...entry,
        day: daysOfWeek[start.getDay()],
        date: getDayString(start),
        from: getTimeString(start),
        to,
      }

      // console.log('#######################')
      // console.log({
      //   entry,
      //   startDay: start.getDay(),
      //   newEntry,
      // })

      return newEntry
    }),
    R.sortWith([
      R.ascend((show) => {
        return new Date(show.start).getTime()
      }),
      R.ascend((show) => {
        return floors.indexOf(show.floor)
      }),
    ]),
  )(artists)

  // console.log('#######################')
  // console.log('#######################')
  // console.log('#######################')
  // console.log({
  //   artists,
  //   timetable,
  // })
  return timetable
}
