import React, { FC } from 'react'
import styled from 'styled-components'
import colors from '../colors'
import { ReactComponent as Plus } from '../../images/plus.svg'

const InputContainer = styled.div<{ disabled: boolean }>`
  background: none;
  border: 1px solid white;
  font-size: 14px;
  color: white;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:focus {
    background-color: black;
  }
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  border-radius: 25px;
`

const InputElement = styled.input`
  padding: 12px 1rem;
  margin: 0;
  flex: 1;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  min-width: 0;
  ::placeholder {
    color: #999;
  }
`

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  margin-right: 8px;
  background: 'red';
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${colors.primary};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:active {
    background-color: ${colors.highlight};
  }
`

const CloseIcon = styled(Plus)`
  transform: rotate(45deg);
  fill: white;
`

type Props = {
  placeholder: string
  disabled: boolean
  autoFocus: boolean
  value: string
  className: string
  onChange: (value: string) => void
  onEnter: () => void
}

const Input: FC<Props> = ({
  onChange,
  onEnter,
  placeholder,
  disabled,
  value,
  className,
  autoFocus = true,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  // eslint-disable-next-line
  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      onEnter()
      inputRef.current?.blur()
    }
  }

  return (
    <InputContainer disabled={disabled} className={className}>
      <InputElement
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          const newValue = event.target.value
          onChange(newValue)
        }}
        onKeyPress={handleKeyPress}
        ref={inputRef}
        autoFocus={autoFocus}
      />
      <CloseButton disabled={!value} onClick={() => onChange('')}>
        <CloseIcon />
      </CloseButton>
    </InputContainer>
  )
}

export default Input
