import React, { useCallback, useEffect, useMemo } from 'react'
import LanguageKeys, { LangProps } from './de'
import LanguageKeysEn from './en'
import { loadProperty, saveProperty } from '../../helper/LocalStorage'

const de = new LanguageKeys()
const en = new LanguageKeysEn()

const defaultValue = {
  lang: 'de',
  keys: de,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setLang = (id: string) => {}

const LangStateContext = React.createContext(defaultValue)
const LangUpdaterContext = React.createContext(setLang)

const startLang = loadProperty('lang') || undefined

const LangProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [lang, setLang] = React.useState(startLang)

  useEffect(() => {
    saveProperty('lang', lang)
  }, [lang])

  const value = useMemo(() => {
    return {
      lang,
      keys: lang === 'de' ? de : en,
    }
  }, [lang])

  return (
    <LangStateContext.Provider value={value}>
      <LangUpdaterContext.Provider value={setLang}>
        {children}
      </LangUpdaterContext.Provider>
    </LangStateContext.Provider>
  )
}

function useLangState() {
  const countState = React.useContext(LangStateContext)
  // eslint-disable-next-line valid-typeof
  if (typeof countState === undefined) {
    throw new Error('useCountState must be used within a LangProvider')
  }

  return countState
}

function useT() {
  const langState = useLangState()

  const t = useCallback(
    (id: LangProps, data?: any): string => {
      const textItem: any = (langState.keys as any)[id]
      let str: string = textItem || id
      if (textItem instanceof Function) {
        str = textItem(data)
      }
      return str
    },
    [langState],
  )

  return t
}

function useLang() {
  const langState = useLangState()
  return langState.lang
}

function useSetLang() {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const setLang = React.useContext(LangUpdaterContext)
  // eslint-disable-next-line valid-typeof
  if (typeof setLang === undefined) {
    throw new Error('useSetLang must be used within a LangProvider')
  }

  return setLang
}

export { LangProvider, useSetLang, useT, useLang }
