import React, { FC } from 'react'
import styled from 'styled-components'
// import colors from '../components/colors'
// import FilterDisplay from './FilterDisplay'
// import { device } from '../device'
import DayButtons from './DayButtons'
import SearchField from './SearchField'
import { colors } from '../components'
import { RootState } from '../state/store'
import { ChangeFilterPayload } from '../state/filter/filterSlice'

// const SelectionContainer = styled.div`
//   padding: 1rem 1rem;
//   @media ${device.mobileM} {
//     padding: 1rem 2rem;
//   }
//   background-color: ${colors.filtersBackground};
// `

const StickyContainer = styled.div<{ isSticky?: boolean }>`
  position: ${(props) => (props.isSticky ? 'sticky' : 'relative')};
  top: -1px;
  z-index: ${colors.zIndex.stickyStuff};
`

const SearchContainer = styled.div`
  padding: 0;
`

type Props = {
  alwaysDayButtons: boolean
  hideDayButtons: boolean
  onlyDayButtons: boolean
  filters: RootState['filter']
  day: string
  changeFilter: (payload: ChangeFilterPayload) => void
  scrollToTop: () => void
}

const Selection: FC<Props> = ({
  alwaysDayButtons,
  hideDayButtons,
  onlyDayButtons,
  filters,
  day,
  changeFilter,
  scrollToTop,
}) => (
  <StickyContainer isSticky>
    {!onlyDayButtons && (
      <>
        <SearchContainer>
          <SearchField changeFilter={changeFilter} />
        </SearchContainer>
      </>
    )}

    {(!filters.search || alwaysDayButtons) && !hideDayButtons && (
      <DayButtons
        day={day}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onDayClick={(day) => {
          changeFilter({ filterId: 'days', value: day })
          scrollToTop()
        }}
      />
    )}
  </StickyContainer>
)

export default Selection
