import React, { FC, useEffect, useState } from 'react'
import {
  Page,
  Content,
  Message,
  SpecialContent,
  H5,
  Text,
  H3,
  Caption,
  Spacer,
  colors,
} from '../components'
import styled from 'styled-components'
import { PageTitle } from '../components/PageTitle'
import { PulsingCircle } from './PulsingCircle'
import { Config } from '../const'
import { getDayString, getTimeString } from '../helper/DateFornat'
import { useLang } from '../state/language'
import { BottomArea } from '../BottomArea/BottomArea'

const dummyInfo = {
  isActive: false,
  shuttle: {
    status: 'yellow',
    headline: 'ca. 4 Stunden Wartezeit',
    headlineEn: 'ca. 4 hours waiting time',
    message:
      'bleibt lieber noch auf dem Platz, die Schlange ist gerade noch zu lang.',
    messageEn: 'better stay on the campsite, the queue is still too long.',
    lastUpdate: 'Sonntag um 15:35',
  },
  bassliner: {
    status: 'green',
    headline: 'Alles cool',
    headlineEn: 'all fine',
    message:
      'keine Ausserplanmäßigen Verspätungen oder Busausfälle. Es gibt noch Fahrkarten.',
    messageEn: 'better stay on the campsite, the queue is still too long.',
    lastUpdate: 'Sonntag um 15:35',
  },
  lastUpdate: 'Sonntag um 15:35',
}

type Info = typeof dummyInfo

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const TitleCircleOuter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #848283;
  padding-bottom: 12px;
`

const InfosOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding-top: 16px;
`

type Props = {
  // onClose: () => void
}

export const Departure: FC<Props> = () => {
  const [info, setInfo] = useState<Info | undefined>(undefined)
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)

  const lang = useLang()

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined
    const loadInfo = async () => {
      const url = Config.REACT_APP_INFO_URL
      try {
        const result = await fetch(url, {
          mode: 'no-cors',
          cache: 'no-cache',
        })
        const loadedInfo: Info = await result.json()
        setInfo(loadedInfo)
        setLoading(false)

        timeout = setTimeout(loadInfo, Config.REACT_APP_INFO_UPDATE_TIME)
      } catch (error) {
        setHasError(true)
        setLoading(false)
      }
    }
    loadInfo()
    setLoading(true)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const date = info?.lastUpdate ? new Date(info.lastUpdate) : new Date()

  return (
    <Overlay>
      <Page withBackground alignLeft center>
        <PageTitle title={<Message id="TITLE_PAGE_DEPARTURE" />} />
        <Content>
          {(!info || !info.isActive) && (
            <>
              <SpecialContent>
                <InfosOuter>
                  <H3>
                    <Message id="DEPARTURE_NO_INFO_HEADLINE" />
                  </H3>

                  <Text>
                    <Message id="DEPARTURE_NO_INFO_MESSAGE" />
                  </Text>

                  {hasError && (
                    <>
                      <Text color={colors.error.textColor}>
                        <Message id="DEPARTURE_LOAD_ERROR" />
                      </Text>
                    </>
                  )}

                  {loading && (
                    <>
                      <Text>
                        <Message id="DEPARTURE_LOADING" />
                      </Text>
                    </>
                  )}

                  <Caption>
                    <Message id="DEPARTURE_LAST_UPDATE" />{' '}
                    {getDayString(date) + ' ' + getTimeString(date)}
                  </Caption>
                </InfosOuter>
              </SpecialContent>
            </>
          )}

          {info?.isActive && (
            <>
              <SpecialContent>
                <TitleCircleOuter>
                  <H5>
                    <Message id="PAGE_DEPARTURE_NEUSTRELITZ" />
                  </H5>
                  <PulsingCircle status={info.shuttle.status} />
                </TitleCircleOuter>

                <InfosOuter>
                  <H3>
                    {lang === 'de'
                      ? info.shuttle.headline
                      : info.shuttle.headlineEn}
                  </H3>

                  <Text>
                    {lang === 'de'
                      ? info.shuttle.message
                      : info.shuttle.messageEn}
                  </Text>

                  <Caption>
                    <Message id="DEPARTURE_LAST_UPDATE" />{' '}
                    {getDayString(date) + ' ' + getTimeString(date)}
                  </Caption>
                </InfosOuter>
              </SpecialContent>
              <Spacer />
              <SpecialContent>
                <TitleCircleOuter>
                  <H5>
                    <Message id="PAGE_DEPARTURE_BASSLINER" />
                  </H5>
                  <PulsingCircle status={info.bassliner.status} />
                </TitleCircleOuter>

                <InfosOuter>
                  <H3>
                    {lang === 'de'
                      ? info.bassliner.headline
                      : info.bassliner.headlineEn}
                  </H3>

                  <Text>
                    {lang === 'de'
                      ? info.bassliner.message
                      : info.bassliner.messageEn}
                  </Text>

                  <Caption>
                    <Message id="DEPARTURE_LAST_UPDATE" />{' '}
                    {getDayString(date) + ' ' + getTimeString(date)}
                  </Caption>
                </InfosOuter>
              </SpecialContent>
            </>
          )}
        </Content>
        <BottomArea showFilterButton={false} />
      </Page>
    </Overlay>
  )
}
