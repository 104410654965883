import saveStoreMiddleWare from './saveStoreMiddleware'
import { configureStore, createSelector } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import timetable from './timetable/timetableSlice'
import filter from './filter/filterSlice'

// const logEnabled = process.env.NODE_ENV === 'development'

const DEBUG =
  process.env.NODE_ENV === 'development' ||
  window.location.pathname.includes('isDebug')

// Automatically adds the thunk middleware and the Redux DevTools extension
export const store = configureStore({
  devTools: DEBUG,
  reducer: {
    timetable,
    filter,
  },
  // preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger).concat(saveStoreMiddleWare),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const createAppSelector = createSelector.withTypes<RootState>()
