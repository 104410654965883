import React, { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import { colors } from '../components'
import { ReactComponent as Plus } from '../images/icons_2023/add.svg'
import { ReactComponent as Minus } from '../images/icons_2023/minus.svg'
import { ReactComponent as InfoIcon } from '../images/icons_2023/artist-details.svg'

type FavoriteProps = {
  $isFavorite?: boolean
  $isWhite?: boolean
  $isSmall?: boolean
  $toggleColor?: boolean
}

type PlusButtonProps = FavoriteProps & {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  variant?: 'plus' | 'minus'
}

const SPlusButton = styled.div<FavoriteProps>`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;
  box-sizing: border-box;

  svg {
    /* fill: ${(props) =>
      props.$isFavorite ? colors.plusButton : colors.plusButtonFavorite}; */
    width: 32px;
    height: 32px;
    /* transform: scale3d(1.3, 1.3, 1.3); */
  }
`

export const SPlusIcon = styled.div<FavoriteProps>`
  transform: ${(props) =>
    props.$isFavorite ? 'rotate(45deg)' : 'rotate(0deg)'};
  transition: transform 0.2s ease-out;
  width: 32px;
  height: 32px;

  ${(props) =>
    props.$toggleColor &&
    `
 & .bg {
    fill: ${props.$isFavorite ? colors.white : colors.primary};
  }

  & .content {
    fill: ${props.$isFavorite ? colors.plusButton : colors.white};
  }
  `}
`

export const PlusButton: FC<PlusButtonProps> = ({
  $isFavorite,
  onClick,
  variant = 'plus',
  $toggleColor,
}) => {
  return (
    <SPlusButton
      $isFavorite={$isFavorite}
      onClick={(event) => {
        onClick && onClick(event)
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <SPlusIcon $isFavorite={$isFavorite} $toggleColor={$toggleColor}>
        {variant === 'plus' ? <Plus /> : <Minus />}
      </SPlusIcon>
    </SPlusButton>
  )
}

export const SInfoButton = styled(SPlusButton)`
  background: none;

  & .bg {
    fill: ${(props) => {
      if (props.$isWhite) {
        return props.$isFavorite ? colors.text : colors.textInverted
      }

      return props.$isFavorite ? colors.white : colors.primary
      // props.$isFavorite || props.$isWhite ? colors.white : '#B84740'
      return 'red'
    }};
  }

  & svg {
    width: ${(props) => (props.$isSmall ? '24px' : '32px')};
    height: ${(props) => (props.$isSmall ? '24px' : '32px')};
  }
`

export const InfoButton: FC<PlusButtonProps> = ({
  onClick,
  $isFavorite,
  $isWhite,
  $isSmall,
}) => {
  return (
    <SInfoButton
      onClick={onClick}
      $isFavorite={$isFavorite}
      $isWhite={$isWhite}
      $isSmall={$isSmall}
    >
      <InfoIcon />
    </SInfoButton>
  )
}
