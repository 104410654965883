import React, { useEffect, useState } from 'react'
import { H5, Message, SmallButton, SpecialContent, colors } from '../components'
import styled from 'styled-components'
import { ArtistWithShows } from '../TimetableList/selectors'
import { useAppDispatch } from '../state/hooks'
import { setNote, setTag } from '../state/timetable/timetableSlice'

type Props = {
  artist: ArtistWithShows
}

const InputElement = styled.textarea`
  padding: 1rem;
  margin: 0;
  flex: 1;
  background-color: transparent;
  border: 1px solid ${colors.primaryDark};
  color: white;
  font-size: 18px;
  min-width: 0;
  ::placeholder {
    color: #999;
  }
  width: 100%;
  box-sizing: border-box;
`

export const ArtistNotesAndHighlight: React.FC<Props> = ({ artist }) => {
  const dispatch = useAppDispatch()
  const [notes, setNotes] = useState(artist?.extras?.note || '')

  useEffect(() => {
    setNotes(artist?.extras?.note || '')
  }, [artist])

  if (!artist) {
    return null
  }

  return (
    <>
      <SpecialContent>
        <H5 mb={3}>
          <Message id="ARTIST_NOTES" />
        </H5>
        <InputElement
          placeholder=""
          autoFocus={false}
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          rows={3}
        />
        <SmallButton
          mt={5}
          onClick={() => {
            console.log('save')
            dispatch(
              setNote({
                artistId: artist.id,
                note: notes,
              }),
            )
          }}
        >
          <Message id="ARTIST_NOTES_SAVE" />
        </SmallButton>
      </SpecialContent>
      {artist.isFavorite && (
        <SmallButton
          active={!!artist.extras?.tag}
          mt={6}
          onClick={() => {
            dispatch(
              setTag({
                artistId: artist.id,
                tag: '🚀',
              }),
            )
          }}
        >
          <Message
            id={
              artist.extras?.tag
                ? 'ARTIST_HIGHLIGHT_REMOVE'
                : 'ARTIST_HIGHLIGHT'
            }
          />
        </SmallButton>
      )}
    </>
  )
}
