import { EXITS, FLOORS, WATER, WC } from '../layers'

export type FsnCoord = [number, number]
export type Route = FsnCoord[]

export interface Coordinate {
  longitude: number
  latitude: number
}
export interface FsnLatLng {
  lng: number
  lat: number
}

export type FsnLayerId =
  | 'floors'
  | 'exits'
  | 'water'
  | 'wc'
  | 'camping'
  | 'festival'

export const CAMPING_LAYERS: FsnLayerId[] = [FLOORS, EXITS, WATER, WC]

export type NearbyType = 'Water' | 'Exit' | 'Wc' | 'Shower'
