import React from 'react'
import styled from 'styled-components'
import KeyVisualImg from '../images/no-photos.png'
import { Page, Content, Row, Button, BottomDocked } from '../components'
import { VERSION } from '../const'
import { LAST_BUILD_TIME } from '../lastBuildTime'
import { useNavigate } from 'react-router-dom'

const VersionText = styled.div`
  font-size: 12px;
  color: #6e6e6e;
  padding: 0.5rem 0;
`

const KeyVisualContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`
const KeyVisual = styled.img`
  width: 100%;
  max-height: 90%;
  object-fit: contain;
`

const BottomDockedStyled = styled(BottomDocked)`
  padding-bottom: calc(0px + env(safe-area-inset-bottom) * 4);
`

const BottomSpacer = styled.div`
  /* background-color: red; */
  height: 40px;
`

export const NoPhotos = () => {
  const lastUpdateStr = new Date(LAST_BUILD_TIME).toLocaleString()

  const navigate = useNavigate()

  return (
    <Page>
      <Content
        padding={'0'}
        style={
          // eslint-disable-next-line react-native/no-inline-styles
          { flex: 1, paddingBottom: 30 }
        }
      >
        <KeyVisualContainer>
          <KeyVisual src={KeyVisualImg} />
        </KeyVisualContainer>
      </Content>
      <BottomSpacer />

      <BottomDockedStyled className="ios-extra-padding">
        <Row flexDirection="row" alignItems="initial">
          {process.env.REACT_APP_IS_NOT_REALEASED === 'true' ? (
            <div>not released</div>
          ) : (
            <>
              <Button
                onClick={() => {
                  navigate('/timetable')
                }}
                mr={4}
              >
                Weiter
              </Button>
            </>
          )}
        </Row>

        <VersionText>
          V {VERSION} {lastUpdateStr}
        </VersionText>
      </BottomDockedStyled>
    </Page>
  )
}
