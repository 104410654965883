import styled from 'styled-components'
import colors from './colors'

export const BubbleItem = styled.span`
  display: inline-block;
  margin-right: 1rem;
  &:before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid ${colors.primaryDark};
    display: inline-block;
    position: relative;
    top: 1px;
    margin-right: 1rem;
  }
`
