import styled from 'styled-components'

const BottomContainer = styled.div<{ noPaddingBottom?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 0;
  padding-bottom: ${(props) => (props.noPaddingBottom ? 0 : '2rem')};
`

export default BottomContainer
