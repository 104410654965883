import { useEffect } from 'react'

type UseBackButtonProps = {
  onBackClick: () => void
  enabled: boolean
}
export const useBackButton = ({ onBackClick, enabled }: UseBackButtonProps) => {
  useEffect(() => {
    const onBack = (event) => {
      console.log('onBack()')
      event.preventDefault()
      onBackClick()
    }
    if (enabled) {
      console.log('addListener')
      document.addEventListener('backbutton', onBack, false)
    }
    return () => {
      console.log('removeListener')
      document.removeEventListener('backbutton', onBack, false)
    }
  }, [onBackClick, enabled])
}
