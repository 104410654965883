import maplibregl, { Map, Marker } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { useEffect, useRef, useState } from 'react'
import exit from '../images/exit.svg'
import floors from '../images/floors.svg'
import locator from '../images/locator.svg'
import locked from '../images/locked.svg'
import north from '../images/north.svg'
import tent from '../images/tent.svg'
import unlocked from '../images/unlocked.svg'
import water from '../images/water.svg'

import { Message } from '../components'
import wc from '../images/wc.svg'
import { useT } from '../state/language'
import classes from './Map.module.css'
import { MapButton } from './components/map-button/MapButton'
import {
  FUSION_CENTER,
  MAX_NE,
  MAX_SW,
  NE,
  SW,
  TENT_ICON_KEY,
  TENT_INITIAL_POSITION,
  TENT_LOCKED_KEY,
} from './constants/map'
import { waitMs } from './helpers/map'
import { EXITS, FLOORS, WATER, WC, addLayers } from './layers'
import { CAMPING_LAYERS, FsnLayerId } from './models/map'
import { createTent } from './tent'

const geolocator = new maplibregl.GeolocateControl({
  positionOptions: {
    enableHighAccuracy: true,
  },
  trackUserLocation: true,
})

const MAX_ZOOM = 15.6

export function MapComponent() {
  const t = useT()

  const dragMeText = t('TENT_DRAG_ME')
  const lockMeText = t('TENT_LOCK_ME')

  const mapContainer = useRef(null)

  const [userLocation, setUserLocation] = useState<{
    longitude: number
    latitude: number
  } | null>(null)

  const [tentMarker, setTentMarker] = useState<Marker | null>(null)
  const [tentLocked, setTentLocked] = useState<boolean>(false)
  const [locationDenied, setLocationDenied] = useState<boolean>(false)
  const [theMap, setTheMap] = useState<Map | null>(null)
  const [bearing, setBearing] = useState<number>(0)

  const [layersViz, setLayersViz] = useState(
    CAMPING_LAYERS.reduce((a, layer) => {
      a[layer] = 'visible'

      return a
    }, {}),
  )

  useEffect(() => {
    const tentLockedString = localStorage.getItem(TENT_LOCKED_KEY)

    if (tentLockedString) {
      setTentLocked(JSON.parse(tentLockedString))
    }
  }, [])

  useEffect(() => {
    const highlightMarker = async () => {
      if (tentMarker) {
        tentMarker.setRotation(20)
        await waitMs(100)
        tentMarker.setRotation(0)

        if (tentLocked) {
          tentMarker.addClassName('locked')
          const popup = tentMarker.getPopup()
          if (popup?.isOpen()) {
            tentMarker.togglePopup()
          }
        } else {
          tentMarker.removeClassName('locked')
        }
      }
    }

    if (tentMarker) {
      tentMarker.setDraggable(!tentLocked)
      highlightMarker()
    }
  }, [tentLocked, tentMarker])

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current!,
      style: { version: 8, sources: {}, layers: [] },
      center: FUSION_CENTER,
      maxBounds: [MAX_SW, MAX_NE],
      zoom: 13,
      minZoom: 12.8,
      maxZoom: MAX_ZOOM,
      pitchWithRotate: false,
      touchPitch: false,
    })
    map.addControl(geolocator)

    map.on('load', () => {
      setTheMap(map)
    })

    map.on('rotate', () => {
      setBearing(map.getBearing())
    })

    geolocator.once('geolocate', () => {
      map.fitBounds([SW, NE])
    })

    geolocator.on('geolocate', (data) => {
      if (data?.coords) {
        setUserLocation(data.coords)
      }
    })

    return () => {
      if (map) {
        map.remove()
      }
    }
  }, [])

  useEffect(() => {
    if (!theMap) return

    theMap.fitBounds([SW, NE])

    addLayers(theMap)
    const theTent: Marker = createTent(theMap, dragMeText, lockMeText)

    setTentMarker(theTent)

    geolocator.trigger()
  }, [theMap, dragMeText, lockMeText])

  const centerMe = () => {
    if (locationDenied) {
      return
    }

    if (!userLocation) {
      geolocator.trigger()
      return
    }

    theMap!.flyTo({
      center: [userLocation.longitude, userLocation.latitude],
      zoom: MAX_ZOOM,
      speed: 2,
      curve: 1.42,
    })
  }

  const findMyTent = () => {
    const tentLocationState = localStorage.getItem(TENT_ICON_KEY)
    const tentLocation = tentLocationState
      ? JSON.parse(tentLocationState)
      : { lng: TENT_INITIAL_POSITION[0], lat: TENT_INITIAL_POSITION[1] }

    theMap!.flyTo({
      center: [tentLocation.lng, tentLocation.lat],
      zoom: MAX_ZOOM,
      speed: 2,
      curve: 1.42,
    })
  }

  const toggleTentLock = () => {
    setTentLocked(!tentLocked)
    localStorage.setItem(TENT_LOCKED_KEY, String(!tentLocked))
  }

  const resetNorth = () => {
    if (!theMap) return
    theMap?.resetNorth()
  }

  const toggleLayerViz = (layerId: FsnLayerId) => {
    if (!theMap) return

    const visibility = layersViz[layerId] === 'visible' ? 'none' : 'visible'

    theMap.setLayoutProperty(layerId, 'visibility', visibility)

    setLayersViz({
      ...layersViz,
      [layerId]: visibility,
    })
  }

  const locationGranted = () => {
    return
  }

  const locationGrantError = (err) => {
    if (err.code === 1) {
      setLocationDenied(true)
    }
  }

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      locationGranted,
      locationGrantError,
    )
  }

  return (
    <>
      <div className={classes.buttons}>
        <MapButton
          onClick={() => toggleLayerViz(FLOORS)}
          isActive={layersViz[FLOORS] === 'visible'}
          iconSrc={floors}
        ></MapButton>

        <MapButton
          onClick={() => toggleLayerViz(WC)}
          isActive={layersViz[WC] === 'visible'}
          iconSrc={wc}
        ></MapButton>
        <MapButton
          onClick={() => toggleLayerViz(WATER)}
          isActive={layersViz[WATER] === 'visible'}
          iconSrc={water}
        ></MapButton>
        <MapButton
          onClick={() => toggleLayerViz(EXITS)}
          isActive={layersViz[EXITS] === 'visible'}
          iconSrc={exit}
        ></MapButton>
      </div>

      <div className={`${classes.buttons} ${classes.right}`}>
        <MapButton
          isActive={!!userLocation}
          onClick={centerMe}
          iconSrc={locator}
        ></MapButton>
        <MapButton
          onClick={resetNorth}
          isActive={bearing !== 0}
          bearing={bearing}
          iconSrc={north}
        ></MapButton>
        <MapButton
          isActive={true}
          onClick={findMyTent}
          iconSrc={tent}
        ></MapButton>
        <MapButton
          isActive={tentLocked}
          onClick={toggleTentLock}
          iconSrc={tentLocked ? locked : unlocked}
        ></MapButton>
      </div>

      {locationDenied && (
        <div className={classes.info}>
          <div className={classes.message}>
            <Message id="LOCATION_DENIED" />
          </div>
        </div>
      )}

      <div ref={mapContainer} className={classes.map} />
    </>
  )
}
